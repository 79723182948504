<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col cols="12">
        <v-card class="light">
          <v-app-bar color="light" class="border-left-primary">
            <v-toolbar-title>{{ $t("sidebar.Customers") }}</v-toolbar-title>
            <button
              class="py-5 px-6 mb-0 ms-auto"
              v-if="
                $store.getters['auth/str_per'].indexOf('customer-create') > -1
              "
              @click="add_customer()"
            >
              <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
            </button>
          </v-app-bar>
          <!-- {{ users }} -->
          <FormFilter
            :filters="filters"
            @get_data="getCustomers"
            @reset="resetFilters"
          ></FormFilter>
          <Table
            :loading="loading"
            :headers="header"
            :items="users"
            :paginate="paginate"
            :btns="btns"
            :btn_table="btn_table"
            :btn_loading="btn_loading"
            @action="action"
            @get_data_paginate="get_data_paginate"
            @event_btn="event_btn"
            @set_option="set_option"
            @change_pre_page="ChangePrePage"
            ref="table"
          ></Table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Table from "../../Components/PaginationTable.vue";
import FormFilter from "../../Components/Filter.vue";
import UserService from "../../../services/user.service";
export default {
  name: "Dashboard",
  components: {
    Table,
    FormFilter,
  },
  data() {
    return {
      filters: [],
      options: {},
      btn_table: {
        excel: true,
        print: true,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      users: [],
      btns: [
        {
          type: "icon",
          text: "profile",
          color: "bg-gradient-info",
          icon: "mdi-account-tie",
        },
      ],
      loading: true,
      header: [
        // { text: this.$i18n.t("ID"), value: "users.id", align: "center" },
        {
          text: this.$i18n.t("group.Name"),
          value: "avatar",
          align: "center",
        },
        {
          text: this.$i18n.t("Company Name"),
          value: "users.company",
          align: "center",
        },
        {
          text: this.$i18n.t("user.Email"),
          value: "users.email",
          align: "center",
        },
        {
          text: this.$i18n.t("auth.Phone Number"),
          value: "users.phone",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Created at"),
          value: "users.created_at",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      error_msg: "",
      paginate: {
        page: 1,
        itemsPerPage: 0,
        total: 0,
      },
    };
  },
  methods: {
    action(id, name, item) {
      if (name == "profile") {
        this.profile(id);
      }
    },
    profile(id) {
      this.$router.push({ path: `applicant/profile/${id.toString()}` });
    },
    set_option(val) {
      this.options = val;
    },
    get_data_paginate(page) {
      this.paginate.page = page;
      this.getCustomers();
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.getCustomers(false, "pre_page");
    },
    resetFilters() {
      this.paginate.page = 1;
      this.getCustomers(true);
    },
    getCustomers(reset = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      return UserService.get_customers(this.paginate.page, formData).then(
        (response) => {
          // console.log(response);
          this.loading = false;
          this.paginate.total = response.data.users.total;
          this.paginate.itemsPerPage = parseInt(response.data.users.per_page);
          this.users = response.data.users.data;

          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.$refs.table.resetOption();
          }
        },
        (error) => {
          // console.log(error);
          this.loading = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
    event_btn(name) {
      if (name == "excel") {
        this.export_excel();
      } else if (name == "print") {
        this.print_table();
      }
    },
    print_table() {
      window.print();
    },
    add_customer() {
      return this.$router.push({ name: "CreateCustomer" });
    },
    export_excel() {
      this.btn_loading.excel = true;
      const formData = new FormData();
      this.filters.filter(function (filter) {
        formData.append(filter.name, filter.value);
      });
      this.$http({
        url: this.$baseURL + "api/users/exportCustomers",
        responseType: "blob",
        method: "post",
        data: formData,
      }).then(
        (response) => {
          this.btn_loading.excel = false;
          // console.log(response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Applicants_" + new Date().toLocaleString() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.btn_loading.excel = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
  },
  mounted() {
    this.getCustomers(true);
    document.title = this.$i18n.t("sidebar.Customers");
  },
};
</script>
